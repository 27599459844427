import { appWithI18Next, useSyncLanguage } from 'ni18n'
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import { wrapper } from '@redux/store'
import Router from 'next/router'
import NProgress from 'nprogress'
import { ni18nConfig } from '../../ni18n.config'
import ErrorBoundary from '@containers/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import { SessionProvider } from 'next-auth/react'
import { OrganizationProvider } from '@context/organization/OrganizationContext'
import { Settings } from 'luxon'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'react-toastify/dist/ReactToastify.css'
import '@algolia/autocomplete-theme-classic/dist/theme.css'
import '@asdeporte/apolo-components/es/style/index.css'
import '@asdeporte/plus-ui/es/style/index.css'
import '@shared/styles/globals.css'
import '@shared/styles/algolia.css'
import 'dayjs/locale/es'
import { useEffect } from 'react'

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

dayjs.locale('es')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.tz.setDefault('America/Mexico_City')

Settings.defaultLocale = 'es'
Settings.defaultZone = 'America/Mexico_City'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { store, props } = wrapper.useWrappedStore(pageProps)
  useSyncLanguage(props.lang)
  const Layout: any = Component.getLayout || Component

  return (
    <SessionProvider session={pageProps.session}>
      <Provider store={store}>
        <OrganizationProvider>
          <ErrorBoundary>
            <Layout lng={props.lang}>
              <Component {...props} />
            </Layout>
          </ErrorBoundary>
          <ToastContainer theme="colored" />
        </OrganizationProvider>
      </Provider>
    </SessionProvider>
  )
}

export default appWithI18Next(MyApp, ni18nConfig)
