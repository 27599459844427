import { algoliasearch } from 'algoliasearch'
import axios, { AxiosRequestTransformer } from 'axios'
import { GraphQLClient } from 'graphql-request'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import { DateTime, Settings } from 'luxon'
import {
  transformAxiosHeaders,
  transformGraphqlHeaders,
} from '@utils/functions'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/Mexico_City')

Settings.defaultLocale = 'es'
Settings.defaultZone = 'America/Mexico_City'

export const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_API_KEY
)

export const GRAPHQL_HEADERS = {
  Authorization:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJwdWJfbkxiSWpsQ1dOS0FkQl9PM3FnVGFvUSIsInRpbWVzdGFtcCI6MTY2MzYzMjcxMH0.3oC28lUjJ_NU8Nr2l8pmc_ttVCfgqSx5ZPQU-YuFNoA.pub_nLbIjlCWNKAdB_O3qgTaoQ',
}

export const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return DateTime.fromJSDate(new Date(data), {
      zone: 'America/Mexico_City',
    }).toString()
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer)
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateTransformer(value)])
    )
  }
  return data
}

export const UPLOAD_URL = `${process.env.NEXTAUTH_URL}/api/upload`

export const EVENT_AXIOS = axios.create({
  baseURL: process.env.EVENT_API_URL,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

EVENT_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const EVENT_AXIOS_FORMDATA = axios.create({
  baseURL: process.env.EVENT_API_URL,
})

EVENT_AXIOS_FORMDATA.interceptors.request.use(
  async config => {
    const configWithHeaders = await transformAxiosHeaders(config)
    config = configWithHeaders
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => Promise.reject(error)
)

export const USER_AXIOS = axios.create({
  baseURL: process.env.USER_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

USER_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const USER_AXIOS_FORMDATA = axios.create({
  baseURL: process.env.USER_API_URL,
})

USER_AXIOS_FORMDATA.interceptors.request.use(
  async config => {
    const configWithHeaders = await transformAxiosHeaders(config)
    config = configWithHeaders
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => Promise.reject(error)
)

export const INSCRIPTION_AXIOS = axios.create({
  baseURL: process.env.INSCRIPTION_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

INSCRIPTION_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const INSCRIPTION_AXIOS_FORMDATA = axios.create({
  baseURL: process.env.INSCRIPTION_API_URL,
  // headers: AXIOS_HEADERS_MULTI,
})

INSCRIPTION_AXIOS_FORMDATA.interceptors.request.use(
  async config => {
    const configWithHeaders = await transformAxiosHeaders(config)
    config = configWithHeaders
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => Promise.reject(error)
)

export const GENERAL_AXIOS = axios.create({
  baseURL: process.env.GENERAL_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

GENERAL_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const GENERAL_AXIOS_FORMDATA = axios.create({
  baseURL: process.env.GENERAL_API_URL,
})

GENERAL_AXIOS_FORMDATA.interceptors.request.use(
  async config => {
    const configWithHeaders = await transformAxiosHeaders(config)
    config = configWithHeaders
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => Promise.reject(error)
)

export const ORGANIZATION_AXIOS = axios.create({
  baseURL: process.env.ORGANIZATION_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

ORGANIZATION_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const TYR_AXIOS_FORMDATA = axios.create({
  baseURL: process.env.TYR_API_URL,
  // headers: AXIOS_HEADERS_MULTI,
})

TYR_AXIOS_FORMDATA.interceptors.request.use(
  async config => {
    const configWithHeaders = await transformAxiosHeaders(config)
    config = configWithHeaders
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => Promise.reject(error)
)

export const TYR_AXIOS = axios.create({
  baseURL: process.env.TYR_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

TYR_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const FMTRI_AXIOS = axios.create({
  baseURL: process.env.FMTRI_API_URL,
  // headers: AXIOS_HEADERS,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

FMTRI_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const TRIBIKE_AXIOS = axios.create({
  baseURL: process.env.TRIBIKE_API_URL,
})

TRIBIKE_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const KITS_AXIOS = axios.create({
  baseURL: process.env.KITS_API_URL,
})

KITS_AXIOS.interceptors.request.use(
  async config => await transformAxiosHeaders(config),
  error => Promise.reject(error)
)

export const EVENT_GRAPHQL = new GraphQLClient(
  process.env.EVENT_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const USER_GRAPHQL = new GraphQLClient(
  process.env.USER_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const GENERAL_GRAPHQL = new GraphQLClient(
  process.env.GENERAL_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const ORGANIZATION_GRAPHQL = new GraphQLClient(
  process.env.ORGANIZATION_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const INSCRIPTION_GRAPHQL = new GraphQLClient(
  process.env.INSCRIPTION_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const TYR_GRAPHQL = new GraphQLClient(
  process.env.TYR_API_URL + process.env.GRAPHQL_URL,
  { requestMiddleware: transformGraphqlHeaders }
)

export const FMTRI_GRAPHQL = new GraphQLClient(
  process.env.FMTRI_API_URL + process.env.GRAPHQL_URL,
  {
    requestMiddleware: transformGraphqlHeaders,
  }
)

export const GENDERS = {
  f: 'female',
  m: 'male',
  fm: 'mixed',
  mf: 'mixed',
}

export const ASDEPORTE_ORGID = 'b96dc416-2ad2-4dd1-92f8-92856b0278c2'
export const ASDEPORTE_DEV_ORGID = '325c8690-5018-478e-b2e2-1ffe32a0fbbe'

export enum ROLES_CATEGORIES {
  ASDEPORTE = 'ASDEPORTE',
  FMTRI = 'FMTRI',
  EVENT = 'EVENT',
  APOLO = 'APOLO',
  'APOLO-SUSCRIPTIONS' = 'APOLO-SUSCRIPTIONS',
}

export enum ROLES_SLUG {
  EVENTS = 'events',
  EVENT_COUPON = 'event-coupon',
  EVENT_TSHIRT = 'event-tshirt',
  EVENT_INSCRIPTION = 'event-inscription',
  EVENT_IMPORT_INSCRIPTIONS = 'event-import-inscriptions',
  EVENT_BENEFITS = 'event-benefits',
  EVENT_FOLLOWUP = 'event-followup',
  EVENT_TYR = 'event-tyr',
  EVENT_SPONSORS = 'event-sponsors',
  EVENT_DELIVERY = 'event-delivery',
  EVENT_ADMIN_INSCRIPTION = 'event-admin-inscription',
  CONVOCATORY = 'convocatory',
  EVENT_EXONERATION = 'event-exoneration',
  USER_ROLES = 'user-roles',
  USERS_ORGANIZATION = 'users-organization',
  ORGANIZATIONS = 'organizations',
  ASENTER = 'asenter',
  PUSH_NOTIFICATIONS = 'push-notifications',
  WEB_USERS = 'web-users',
  WEB_STAGE = 'web-stage',
  WEB_CAROUSEL = 'web-carousels',
  WEB_SERIALS = 'web-serials',
  ASDEPORTE_WALLET = 'asdeporte-wallet',
  ASDEPORTE_ADMIN_WALLET = 'asdeporte-admin-wallet',
  EXTERNAL_INSCRIPTIONS = 'external-inscriptions',
  APOLO_SUSCRIPTIONS = 'apolo-suscriptions',
  APOLO_CREATE_SUSCRIPTIONS = 'create-suscriptions',
  APOLO_CANCEL_SUSCRIPTIONS = 'cancel-suscriptions',
  FMTRI_AFFILIATIONS = 'fmtri-affiliations',
  FMTRI_AFFILIATION_TYPES = 'fmtri-affiliation-types',
  FMTRI_AFFILIATE_USERS = 'fmtri-affiliate-users',
  REPORT_TRIBIKE = 'report-tribike',
  REPORT_INNOVASPORT = 'report-innovasport',
  PROMOTION_BIN = 'promotion-bin',
  MERGE_USERS = 'merge-user',
  INBODY = 'inbody',
  NORTE = 'norte',
  BAJIO = 'bajio',
  SURESTE = 'sureste',
  SUR = 'sur',
  NORESTE = 'noreste',
  CENTRO = 'centro',
  NACIONAL = 'national',
}
